




















body {
  background: #fff;
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}
.stat-container {
  padding-top: 20px;
}
.container {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
